<template>
  <nuxt-link
    :class="$style.root"
    :to="localePath(props.to, locale)"
  >
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const localePath = useLocalePath()
const idle = ref(false)

const props = defineProps({
  to: {
    type: [String, Object]
  },
  locale: {
    type: String,
    optional: true
  }
})
</script>

<style module>
.root[disabled] {
}
</style>
